import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Form, } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const DashboardBanner = (props) => {
    const [dashModal, setDashModal] = useState();
    const [thumbimage, setThumbImage] = useState("");

    const openModal = () => { setDashModal(!dashModal); };
    const getImgThumbHandler = (img) => {
        if (img.substring(img.lastIndexOf('.') + 1) == "pdf") { window.open(img, "_blank"); }
        else { setThumbImage(img); openModal(); }
    }
    const customLoader = ({ src, width, quality }) => {
        return `${src}?w=${width}&q=${quality || 75}`
    }
    return (
        <>
            <div className='nwCarouselWrap'>
                <div className='nwCarouselHeading'>
                    {props.bannername}
                </div>
                <div className='nwCarouselThumb'>
                    <img src={props.thumbnail} className='img-fluid' onClick={() => getImgThumbHandler(props.banner)} />
                </div>
            </div>
            {dashModal &&
                <Modal isOpen={dashModal} toggle={openModal} centered={true} size="lg">
                    <ModalHeader toggle={openModal}>Preview</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <img src={thumbimage} className='img-thumbnail img-fluid' />
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            }
        </>
    )
}

export default DashboardBanner