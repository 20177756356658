import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { FrontDesk_URL } from '../../constant';
import DataTables from '../commonComponent/DataTable/DataTables';
import Breadcrumb from '../../components/common/breadcrumb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router';

var glbRecords = [];
var dates = "";
const TestStatus = () => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const current = new Date();
        const date = current.getDate() < "10" ? "0" + current.getDate() : current.getDate();
        const month = (current.getMonth() + 1) < "10" ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
        const year = current.getFullYear();
        dates = `${year}-${month}-${date}`;
        formik.values.fromdate = `${year}-${month}-${date}`;
        formik.values.todate = `${year}-${month}-${date}`;
        BindGrid("", "", dates, dates, "");
    }, [])

    const paginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="View Report" placement="top" linkId="View" onClick={() => editHandler(rowData.VisitingId)} faName="fa fa-eye mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const statusFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Status === "0" ? "Ordered" :
                        rowData.Status === "1" ? "Collected" :
                            rowData.Status === "2" ? "Submitted" :
                                rowData.Status === "3" ? "Report Generated" :
                                    rowData.Status === "4" ? "Sample Collected" :
                                        rowData.Status === "5" ? "Sample Department Received" :
                                            rowData.Status === "6" ? "Rejected" :
                                                rowData.Status === "10" ? "Partial Report Generated" :
                                                    null
                }
            </div>
        </React.Fragment>
    }
    const editHandler = async (vId) => {
        localStorage.setItem('vId', vId);
        //history.push({ pathname: `${process.env.PUBLIC_URL}/lab-report/overview`, search: `` });
        window.open(`${process.env.PUBLIC_URL}/lab-report/overview`, '_blank');
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visiting Id', editable: false },
        { dataField: 'PatientName', text: 'Patient Name', editable: false },
        { dataField: 'OrderDate', text: 'Order Date', editable: false },
        { dataField: 'VisitSource', text: 'Source', editable: false },
        // { dataField: 'Status', text: 'Status', formatter: statusFormatter, editable: false },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: { mobileno: '', visitno: '', fromdate: '', todate: '', pName: '' },
        validationSchema: Yup.object({
            mobileno: Yup.string().max(10, 'Mobile no must be 10 numbers').min(10, 'Mobile no must be 10 numbers').matches(phoneRegExp, 'Mobile no is not valid')
        }),
        onSubmit: values => { handleSearch(values); },
    });
    const handleSearch = async (values) => {
        if (values.mobileno === "" && values.visitno === "" && values.fromdate === "" && values.todate === "") { SweetAlert.fire("Atleast one of the above fields is mandatory for searching"); return false; }
        else if (values.fromdate === "" && values.todate !== "") { SweetAlert.fire("From date is mandatory if you had selected to date"); return false; }
        else if (values.todate === "" && values.fromdate !== "") { SweetAlert.fire("To date is mandatory if you had selected from date"); return false; }
        else { BindGrid(values.mobileno, values.visitno, values.fromdate, values.todate, values.pName); }
    }
    const BindGrid = async (mNo, vNo, fDte, tDte, pName) => {
        setTableData([]);
        setIsLoading(true);
        const requestOptions = { MobileNo: mNo, VisitCode: vNo, FromDate: fDte, ToDate: tDte, CentreId: localStorage.getItem('CentreSNo'), PatientName: pName };
        let url = FrontDesk_URL + 'labreport/searchpatientorders'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            glbRecords = [];
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                }
                setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const Reset = () => {
        formik.values.mobileno = "";
        formik.values.visitno = "";
        const current = new Date();
        const date = current.getDate() < "10" ? "0" + current.getDate() : current.getDate();
        const month = (current.getMonth() + 1) < "10" ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
        const year = current.getFullYear();
        formik.values.fromdate = `${year}-${month}-${date}`;
        formik.values.todate = `${year}-${month}-${date}`;
        setTableData([]);
    }
    const DisableKeyPress = (e) => { e.preventDefault(); return false; }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Test Status" parent="Lab Report" />
            <Container fluid>
                <Row >
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Mobile No</Form.Label>
                                                <Form.Control type='text' name="mobileno" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileno} />
                                                {formik.touched.mobileno && formik.errors.mobileno ? (<div className='text-required'>{formik.errors.mobileno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Patient Name</Form.Label>
                                                <Form.Control type='text' name="pName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pName} />
                                                {formik.touched.pName && formik.errors.pName ? (<div className='text-required'>{formik.errors.pName}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Visit Id</Form.Label>
                                                <Form.Control type='text' name="visitno" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.visitno} />
                                                {formik.touched.visitno && formik.errors.visitno ? (<div className='text-required'>{formik.errors.visitno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control type='date' name="fromdate" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fromdate} onKeyPress={(e) => DisableKeyPress(e)} />
                                                {formik.touched.fromdate && formik.errors.fromdate ? (<div className='text-required'>{formik.errors.fromdate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control type='date' name="todate" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.todate} onKeyPress={(e) => DisableKeyPress(e)} />
                                                {formik.touched.todate && formik.errors.todate ? (<div className='text-required'>{formik.errors.todate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            <Button variant='primary' type='submit'>Search</Button>
                                            <Button variant='secondary' className='ml-2' onClick={Reset}>Reset</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Orders</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default TestStatus